@import '~ethos-design-system/src/components/Media/Media.scss';

$mobile-medium-range-start: 450px;
$tablet-range-start: 600px;
$tablet-medium-range-start: 768px;
$laptop-range-mid: 1024px;
$desktop-medium-range-start: 1440px;

.container {
  @include for-phone-only {
    padding: var(--Space-32) var(--Space-24);
  }

  @include for-tablet-and-laptop {
    padding: var(--Space-64);
  }

  @include for-desktop-only {
    padding: var(--Space-80);
  }

  max-width: 1440px;
  margin: 0 auto;
}

.menus {
  display: flex;
  flex-direction: column;
}

.cookie {
  @include for-phone-only {
    padding-bottom: 120px;
  }

  @include for-tablet-only {
    padding-bottom: var(--Space-48);
  }

  @include for-laptop-only {
    padding-bottom: var(--Space-12);
  }
}

.blankFooter {
  margin-bottom: 120px;
  @include for-tablet-and-up {
    margin-bottom: 140px;
  }
}

.mainMenu {
  display: flex;
  justify-content: space-between;

  @include for-phone-only {
    flex-wrap: wrap;
    > * {
      width: calc(50% - var(--Space-12));
      margin-bottom: var(--Space-40);
    }
  }

  @include for-tablet-and-up {
    &.fourCol {
      > * {
        width: calc(25% - var(--Space-12));
      }
    }
    &.threeCol {
      > * {
        width: calc(33% - var(--Space-12));
      }
    }
  }
}

.telephone {
  color: var(--White) !important;
  display: inline-block;
}

.buttons {
  display: flex;
  justify-content: space-between;
  padding-top: var(--Space-24);

  @media (min-width: $mobile-medium-range-start) {
    max-width: 256px;
    > * {
      max-width: 158px;
    }
  }

  .email {
    color: var(--White);

    &:hover {
      color: var(--GrayPrimary--opaque);
      background: var(--White);
    }
  }

  > * {
    cursor: pointer;
    display: inline-block;
    padding: 13px var(--Space-16);
    width: calc(50% - 5px);
    border: solid 1px var(--White);
    text-align: center;

    @include for-tablet-and-up {
      width: calc(50% - var(--Space-8));
    }
  }
}

.icons {
  display: flex;
  align-items: center;
  margin-top: var(--Space-40);
  margin-bottom: var(--Space-40);

  @include for-phone-only {
    margin-top: var(--Space-32);
  }

  a {
    display: block;
    padding-right: var(--Space-16);
  }

  &.inverted {
    filter: invert(0.75);
  }
}

.flexStretch {
  flex: 2;
}

.universalAccessContainer {
  margin-top: var(--Space-16);
  width: var(--Space-32);
}

.universalAccessIcon {
  width: 100%;
  contain: content;

  > path {
    fill: var(--White);
  }

  > circle {
    fill: #2364ff;
  }
}

.mainMenu {
  flex-grow: 1;
  a {
    color: var(--White);
  }
}

.marketingFooter {
  @include for-phone-and-tablet {
    padding: var(--Space-24);
  }

  @include for-laptop-only {
    padding: var(--Space-24) var(--Space-64);
  }

  @include for-desktop-only {
    max-width: 1440px;
    margin: auto;
    padding: var(--Space-24) var(--Space-80);
  }

  .colophon {
    padding-top: 0;
  }

  .cookie {
    @include for-phone-only {
      padding-bottom: 130px;
    }

    @include for-tablet-only {
      padding-bottom: 120px;
    }

    @include for-laptop-and-up {
      padding-bottom: var(--Space-80);
    }
  }

  .universalAccessContainer {
    float: right;
  }
}

.partnerFooter {
  /* Prevent Chrome, Opera, and Safari from letting these items shrink to smaller than their content's default minimum size. */
  flex-shrink: 0;
  background: var(--GrayLightHover--opaque);
  color: #484848;

  .container {
    @include for-phone-only {
      padding: var(--Space-32) var(--Space-24);
      flex-direction: column;
    }

    @include for-tablet-and-laptop {
      padding: var(--Space-64);
    }

    @include for-desktop-only {
      padding: var(--Space-80);
    }

    display: flex;
    width: 100%;
    margin: 0 auto;
    justify-content: space-between;
  }

  .contact {
    margin-right: 100px;
    max-width: 100%;
    min-width: 170px;
  }

  .rightPart {
    display: flex;
    flex-direction: column;
    justify-content: right;
  }

  .rightUpperPart {
    display: flex;
    justify-content: space-between;

    @include for-phone-only {
      flex-direction: column-reverse;
      margin-top: var(--Space-24);
      gap: var(--Space-24);
    }
  }

  .icons {
    display: flex;
    align-items: center;
    margin: 0px;

    a {
      display: block;
      padding-right: var(--Space-16);
    }
  }

  .socialIcons {
    margin: 0px;
  }

  .colophon {
    @include for-phone-only {
      margin-top: var(--Space-30);
      padding-bottom: var(--Space-20);
    }

    @include for-tablet-and-laptop {
      margin-top: var(--Space-100);
    }

    @include for-desktop-only {
      margin-top: var(--Space-100);
    }

    * {
      color: #484848;
    }

    padding-top: 0;
  }

  .menus {
    display: flex;
    justify-content: space-between;

    @include for-phone-only {
      margin-bottom: 30px;
      gap: 10px;
    }

    @include for-tablet-and-up {
      margin-bottom: 160px;
      margin-right: 30px;
      gap: 30px;
    }

    @media (min-width: $tablet-range-start) and (max-width: $tablet-medium-range-start) {
      max-width: 200px;
    }
  }

  .telephone {
    color: unset !important;
  }

  .mainMenu {
    justify-content: left;
  }

  .menuItem {
    @include for-phone-only {
      padding-bottom: var(--Space-2);
    }

    @include for-laptop-and-up {
      padding-bottom: var(--Space-8);
    }
  }

  .menuItem:not(:last-child) {
    @include for-phone-only {
      padding-bottom: var(--Space-1);
    }

    @include for-laptop-and-up {
      padding-bottom: var(--Space-8);
    }
  }

  .cookie {
    @include for-phone-only {
      padding-bottom: 130px;
    }

    @include for-tablet-only {
      padding-bottom: 120px;
    }

    @include for-laptop-and-up {
      padding-bottom: var(--Space-80);
    }
  }
}

.footer {
  /* Prevent Chrome, Opera, and Safari from letting these items shrink to smaller than their content's default minimum size. */
  flex-shrink: 0;
  background: var(--BrandForest);
  color: var(--White);
}

.colophon {
  display: flex;
  flex-direction: column;
  width: 100%;
  @include for-tablet-only {
    padding-top: var(--Space-40);
  }

  @include for-laptop-and-up {
    padding-top: var(--Space-56);
  }

  * {
    color: var(--White);
  }
}

.menuHeading {
  color: var(--White);
  padding-bottom: var(--Space-16);
}

.menuItem:not(:last-child) {
  padding-bottom: var(--Space-16);
}

@media (min-width: $tablet-range-start) {
  .contact {
    width: 225px;
    margin-right: var(--Space-12);
  }
  .menus {
    flex-direction: row;
    flex-wrap: wrap;
  }
  .mainMenu {
    justify-content: space-between;
    > * {
      width: auto;
    }
  }
}

@media (min-width: $desktop-medium-range-start) {
  .mainMenu {
    > * {
      margin-right: var(--Space-24);
    }
  }
}

@media (min-width: $tablet-range-start) and (max-width: $laptop-range-mid) {
  .menus {
    .mainMenu {
      order: 1;
      width: 100%;
      padding-bottom: var(--Space-64);
    }
    .contact {
      order: 2;
    }
    .colophon {
      order: 3;
      width: calc(100% - 290px);
      padding-top: 0;
    }
  }
}

.treatment {
  .colophon {
    order: 3;
    width: 100%;
    display: flex;
    padding-top: 72px !important;
    max-width: 100% !important;
    @include for-phone-only {
      padding-top: 40px;
    }
  }
}

.treatmentContainer {
  @include for-desktop-only {
    padding-bottom: 24px;
  }
}

@media (min-width: $desktop-medium-range-start) {
  .menus .colophon {
    padding-top: 0;
    margin-left: auto;
    max-width: 300px;
  }
}
